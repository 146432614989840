import React, { useState } from 'react';
import { Version } from '@testneumz/nabc-lib';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';

import { EditableProperty } from './EditableProperty';

const editableProperties = [
  'spaceabovelines',
  'spacelinestext',
  'abovelinestextraise'
];

export function Settings({ context, onCancel, onSave }) {
  const [nabcMode, setNabcMode] = useState(context.nabcMode);
  const [nabcOnlyMode, setNabcOnlyMode] = useState(context.nabcOnlyMode);
  const [scrollEnabled, setScrollEnabled] = useState(context.scrollEnabled);

  function onSaveInternal() {
    const result = {
      nabcMode: nabcMode,
      nabcOnlyMode: nabcOnlyMode,
      scrollEnabled: scrollEnabled,
    };

    for (const p of editableProperties) {
      result[p] = context[p];
    }
    
    onSave(result);
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-8">
        <div className="flex flex-col gap-4">
          <div className="flex gap-8">
            <div className='w-32'>Nabc Mode:</div>
            <div className="flex flex-wrap gap-8">
              <div className="flex items-center">
                <RadioButton inputId="gall" name="gall" value="gall" onChange={(e) => setNabcMode(e.value)} checked={nabcMode === 'gall'} />
                <label htmlFor="gall" className="ml-2">Gall</label>
              </div>
              <div className="flex items-center">
                <RadioButton inputId="laon" name="laon" value="laon" onChange={(e) => setNabcMode(e.value)} checked={nabcMode === 'laon'} />
                <label htmlFor="laon" className="ml-2">Laon</label>
              </div>
            </div>
          </div>
          <div className="flex gap-8">
            <div className='w-32'>Nabc Only Mode:</div>
            <div className="flex items-center">
              <Checkbox inputId="nabcOnlyMode" name="nabcOnlyMode" onChange={e => setNabcOnlyMode(e.checked)} checked={nabcOnlyMode} />
            </div>
          </div>
          <div className="flex gap-8">
            <div className='w-32'>Scroll Enabled:</div>
            <div className="flex items-center">
              <Checkbox inputId="scrollEnabled" name="scrollEnabled" onChange={e => setScrollEnabled(e.checked)} checked={scrollEnabled} />
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-4'>
          {editableProperties.map((p) => <EditableProperty propertyName={p} context={context}></EditableProperty>)}
        </div>
      </div>
      <div className="flex justify-end items-end gap-4">
        <div className="flex-1 justify-center text-gray-600 text-sm">
          Version: {Version.version}
        </div>
        <button className="btn-transparent w-20" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn w-20" onClick={(e) => onSaveInternal()}>
          Save
        </button>
      </div>
    </div>
  )
}
