import React, { useEffect, useState } from 'react';
import { TextareaHighlighted } from './TextareaHighlighted';
import { find_separated_highlight_positions } from '@testneumz/nabc-lib';
import { Accordion, AccordionTab } from 'primereact/accordion';

export function Separated({ lyrics, gabc, nabc, metadata, onChange, activeSyllable, onActiveSyllableChange, scrollEnabled }) {
  const [_lyrics, _setLyrics] = useState(lyrics);
  const [_gabc, _setGabc] = useState(gabc);
  const [_nabc, _setNabc] = useState(nabc);
  const [_metadata, _setMetadata] = useState(metadata);

  const [lyricsHighlight, setLyricsHighlight] = useState([[0, 0]]);
  const [gabcHighlight, setGabcHighlight] = useState([[0, 0]]);
  const [nabcHighlight, setNabcHighlight] = useState([[0, 0]]);

  useEffect(() => {
    if (activeSyllable.eventSource !== 'chant') {
      return;
    }

    const positions = find_separated_highlight_positions({
      lyrics: lyrics,
      gabc: gabc,
      nabc: nabc,
    }, {
      type: 'lyrics',
      index: activeSyllable.index,
      indexType: 'syllable',
    });

    setLyricsHighlight([[positions.lyrics.index, positions.lyrics.length]]);
    setGabcHighlight([[positions.gabc.index, positions.gabc.length]]);
    setNabcHighlight([[positions.nabc.index, positions.nabc.length]]);
  }, [lyrics, gabc, nabc, activeSyllable]);

  function handleSeparatedMetadataChange(event) {
    _setMetadata(event.target.value);
    onChange({
      lyrics: lyrics,
      gabc: gabc,
      nabc: nabc,
      metadata: event.target.value,
    });
  }

  function handleSeparatedLyricsChange(event) {
    _setLyrics(event.target.value);
    onChange({
      lyrics: event.target.value,
      gabc: gabc,
      nabc: nabc,
      metadata: metadata,
    });
  }

  function handleSeparatedGabcChange(event) {
    _setGabc(event.target.value);
    onChange({
      lyrics: lyrics,
      gabc: event.target.value,
      nabc: nabc,
      metadata: metadata,
    });
  }

  function handleSeparatedNabcChange(event) {
    _setNabc(event.target.value);
    onChange({
      lyrics: lyrics,
      gabc: gabc,
      nabc: event.target.value,
      metadata: metadata,
    });
  }

  function handleLyricsSelectionChange(event) {
    handleSelectionChange('lyrics', event);
  }

  function handleGabcSelectionChange(event) {
    handleSelectionChange('gabc', event);
  }

  function handleNabcSelectionChange(event) {
    handleSelectionChange('nabc', event);
  }

  function handleSelectionChange(mode, event) {
    const positions = find_separated_highlight_positions({
      lyrics: lyrics,
      gabc: gabc,
      nabc: nabc,
    }, {
      type: mode,
      index: event.target.selectionStart,
      indexType: 'cursor',
    });

    setLyricsHighlight([[positions.lyrics.index, positions.lyrics.length]]);
    setGabcHighlight([[positions.gabc.index, positions.gabc.length]]);
    setNabcHighlight([[positions.nabc.index, positions.nabc.length]]);
    onActiveSyllableChange({ index: positions.syllableIndex, eventSource: mode });
  }

  return (
    <div className="flex flex-col gap-4 flex-1">
      <Accordion activeIndex={[]}>
        <AccordionTab header="Metadata">
          <textarea id='metadata'
            className='w-full px-4 py-2 rounded-md text-gray-700 border'
            style={{ 'resize': 'none' }}
            rows={5}
            value={_metadata}
            onChange={handleSeparatedMetadataChange}
            spellCheck={false}></textarea>
        </AccordionTab>
      </Accordion>
      <div className="flex-1 flex flex-col">
        <label htmlFor="lyricsInput">Lyrics</label>
        <TextareaHighlighted inputId="lyricsInput" eventKey="lyrics" value={_lyrics} onChange={handleSeparatedLyricsChange} scrollEnabled={scrollEnabled}
          onSelect={handleLyricsSelectionChange} highlights={lyricsHighlight} activeSyllable={activeSyllable} rows={5}></TextareaHighlighted>
      </div>
      <div className="flex-1 flex flex-col">
        <label htmlFor="gabcInput">Gabc</label>
        <TextareaHighlighted inputId="gabcInput" eventKey="gabc" value={_gabc} onChange={handleSeparatedGabcChange} scrollEnabled={scrollEnabled}
          onSelect={handleGabcSelectionChange} highlights={gabcHighlight} activeSyllable={activeSyllable} rows={5}></TextareaHighlighted>
      </div>
      <div className="flex-1 flex flex-col">
        <label htmlFor="nabcInput">Nabc</label>
        <TextareaHighlighted inputId="nabcInput" eventKey="nabc" value={_nabc} onChange={handleSeparatedNabcChange} scrollEnabled={scrollEnabled}
          onSelect={handleNabcSelectionChange} highlights={nabcHighlight} activeSyllable={activeSyllable} rows={5}></TextareaHighlighted>
      </div>
    </div>
  );
}
