import React, { useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Length } from '@testneumz/nabc-lib';

export const EditableProperty = function ({ propertyName, context }) {
  const [value, setValue] = useState(context[propertyName].space);

  function onValueChange(e) {
    setValue(e.value);
    context[propertyName] = new Length(e.value, context[propertyName].unit);
  }

  return <div className="flex gap-4">
    <div className="w-32">
      {propertyName}
    </div>
    <InputNumber value={value} onValueChange={(e) => onValueChange(e)} mode="decimal" maxFractionDigits={10} />
    <span>{context[propertyName].unit}</span>
  </div>;
}
