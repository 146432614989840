import './App.css';
import 'primereact/resources/primereact.min.css';
import {
  ChantContext,
  from_compounded_to_separated_cross_check,
  from_separated_to_compounded_cross_check,
  find_compounded_highlight_positions,
  highlight_syllable,
  find_syllable_group,
  scroll_to_syllable,
} from '@testneumz/nabc-lib';
import React, { useState, useEffect, useRef, useLayoutEffect, useDeferredValue, useMemo } from 'react';
import { Resizable } from 'react-resizable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGear, faCircleInfo, faChevronDown, faCopy, faUpload } from '@fortawesome/free-solid-svg-icons'

import { SplitButton } from 'primereact/splitbutton';
import { Dialog } from 'primereact/dialog';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';

import { Separated } from './Separated';
import { TextareaHighlighted } from './TextareaHighlighted';
import { Settings } from './Settings';
import { Chant } from './Chant';
import { FileUpload } from './FileUpload';

const urlInput = window.location.hash ? decodeURIComponent(window.location.hash.substring(3)) : '';
if (urlInput) {
  window.history.replaceState(null, null, ' ');
}

const DEFAULT_CHANT = `(c3) SPe(e|ta)ci(fhg|to)ó(h|ta)sus(h.|ta) (,) for(hh//hhh.f!gwh/ih/jijvIG|ds-lsx3ds-lsx3cl-qlcl-hhcl-hjcihhlsc2)ma(ig/hi/jh,|clsf|hhhih/ihhg.|ds-to-1vihhprhh) (;) præ(fhg|to) fí(hihhf|to!cl-1)li(hig|toS2)is(hiffe.|to-1cl-) (,) hó(fhg/hfi|taclclpoG-hh)mi(hg|cllsc2)num:(gv.eg/ihh/fgf.|vi-sforhhtoShh) (:) dif(f|ta)fú(hf/ghg|cltohh)sa(ge/f!hhhvFEec|cllsi8tsM-lsx3cilsc2cllsc2) est(de|peS) (,) grá(eg|peSlsl2)ti(g|vi)a(fgFE|pesu2|f!hh/|tsM-lsc3|f!hh|tsM-lsx3|hvGF|vi-hh|heed.|clS-hhcl-hh) (;) in(f|vilsl2) lá(hf/gh!ivFE|clciSpp2lsc2)bi(feec|clpilst2)is(efe/f!hh|qi!cllsc2tsMhh) tu(gxfe/fgED|clpesu2|efDC,|pesu2|ef!hvhvFE|tg1pp2|ff//|ds-hh|ef/ge|sf)is.(c./eec//|vippt1cl-|ed/ec..|cl!pilst3)

<sp>V/</sp>.(::) E(e|ta)ruc(e|ta)tá(e.f!gwhgh|ql!poppt2)vit(hg|cl-) cor(g.h!iwjij|ql!poppt2) me(ji/|clS-|hhf/|tg|ig/|cl|hih,|toS|hhhvFE|dscilsc2|hg/hi|clpq)um(ih/ihhg.|clclS1cl-) (;) ver(gh/iji/jhhg|to!cl!pipp2)bum(g|ta) bo(h.f!gwhg|clql!cl)num:(gv/eg/ihh/fgf.|vi-sforhhtoShh) (:) di(f|ta)co(fi|pe) e(ivivHG|bv-su2|ijhhgh|clhh!pihhppu2vihj)go(hg..|cl-) (;) ó(hv.fhg|vitoShh)pe(g|vi)ra(g|vi) me(gh/iji/jhhg|to!cl!pipp2)a(g.|ta) (,) Re(h.f!gwhg|clql!cllst3)gi:(gv.eg/ihh/fgf.|vi-sforhjtoShj) (:) lin(f|talscm3)gua(f|ta) me(f|ta)a(f|ta) cá(fgfg|tr)la(ge/|cllsc2|f!hhhvFEec|tsM-cihhlsc2clhh)mus(de|peS) (,) scri(eg|peSlss4)bæ(fgFE|pesu2|f!hh/|tsMlsc3|f!hh|tsMlsx3|hvGF|ci-hh|heed.|clS-hhcl-hh) (;) ve(f|ta)ló(hf/|cl|gh!ivFE|cipp2)ci(feec|cllsc2pilst2)ter(efef|trlsc2) (,) scri(hh|bv-)bén(gxfe/|cl|fgED|pesu2|efDC , |pesu2|ef!hvhvFE|tg1pp2|ff//|ds-hh|ef/ge|sf)tis.(c./eec/|tavicl-|ed/ec..|clcl-) (::)

Spe(e)ci(fhg)ó(h)sus(h.) (,) for(hh//hhh.f!gwh/ih/jijvIG)ma(ig/hi/jh,hhhih/ihhg.) (;) præ(fhg) fí(hihhf)li(hig)is(hiffe.) (,) hó(fhg/hfi)mi(hg)num:(gv.eg/ihh/fgf.) (:) dif(f)fú(hf/ghg)sa(ge/f!hhhvFEec) est(de) (,) grá(eg)ti(g)a(fgFEf!hh/f!hhhvGFheed.) (;) in(f) lá(hf/gh!ivFE)bi(feec)is(efe/f!hh) tu(gxfe/fgEDefDC,ef!hvhvFEff//ef/ge)is.(c./eec//ed/ec..) (::)`;

const DEFAULT_CHANT_TEST = '';
// const DEFAULT_CHANT_TEST = ``;
// const DEFAULT_CHANT_TEST = '(c3) ga(gvv) ga(gvvv) ga(gss) ga(gsss)';
// const DEFAULT_CHANT_TEST = '(c3) SPe(GF)'
// const DEFAULT_CHANT_TEST = '(c3) SPe(GF) SPe(GF) SPe(GF) (z0c4) SPe(GF) SPe(GF) SPe(GF)';
// const DEFAULT_CHANT_TEST = '(c3) SPe(e|ta)ci(fhg|to)ó(h|ta)sus(h.|ta)';
// const DEFAULT_CHANT_TEST = '(c3) SPe(GF) g(ggg) (;) g(ggg) (:) g(ggg) (:?) g(ggg) (::) g(ggg) (;1) g(ggg) (;2) g(ggg) (;3) g(ggg) (;4) g(ggg) (;5) g(ggg) (;6) g(ggg) (:\') g(ggg) g(ggg) *(;) gggggggggggg(ggg) (;) g(g.) (::)  ggggag(gggggggg) gggggaggg(ggg)';

library.add(faGear, faCircleInfo, faChevronDown, faCopy, faUpload);

const useDebouncedEffect = (effect, deps, delay) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);
    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
}

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

let timeout = null;

export default function App() {
  const modeOptions = ['Classic', 'Separated'];


  const [context, setContext] = useState(() => ({
    ...new ChantContext(),
    // any custom settings here
  }));

  const [errors, setErrors] = useState({});

  const [compounded, setCompounded] = useState(urlInput || DEFAULT_CHANT_TEST || DEFAULT_CHANT);
  const [debouncedCompounded, setDebouncedCompounded] = useState(compounded);
  const [compoundedHighlight, setCompoundedHighlight] = useState([[0, 0]]);
  const [activeSyllable, setActiveSyllable] = useState({
    index: -1,
    eventSource: '',
  });

  const [mode, setMode] = useState(modeOptions[0]);
  const [separated, setSeparated] = useState({
    lyrics: '',
    gabc: '',
    nabc: '',
    metadata: '',
  });

  const [showFileDialog, setShowFileDialog] = useState(false);
  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const [showInstructionDialog, setShowInstructionDialog] = useState(false);

  const deferredCompounded = useDeferredValue(debouncedCompounded);

  const [settings] = useState({
    debug: false,
  });

  const [leftPanelWidth, setLeftPanelWidth] = useState(600);
  // eslint-disable-next-line no-unused-vars
  const [fullWindowWidth, _] = useWindowSize();

  const [dragging, setDragging] = useState(false);

  useDebouncedEffect(() => {
    setContext({
      ...context,
      lineWidthPx: fullWindowWidth - leftPanelWidth - 80,
    })
  }, [leftPanelWidth, fullWindowWidth], 500);

  useDebouncedEffect(() => {
    setDebouncedCompounded(compounded);
  }, [compounded], 500);

  useEffect(() => {
    if (mode === 'Classic') {
      // eslint-disable-next-line no-unused-vars
      const [result, _] = from_compounded_to_separated_cross_check(deferredCompounded);
      setSeparated(result);
    }
  }, [deferredCompounded, mode]);

  useEffect(() => {
    if (activeSyllable.index === -1) {
      return;
    }
    const query = document.getElementsByClassName('gregorio-chant');
    if (query.length > 0) {
      highlight_syllable(query[0], activeSyllable.index, { fill: 'red' }, {});

      if (context?.scrollEnabled) {
        if (activeSyllable.eventSource !== 'chant') {
          const parent = document.getElementById('scroll-parent');
          scroll_to_syllable(parent, query[0], activeSyllable.index);
        }
        if (activeSyllable.eventSource !== 'classic') {
          const result = find_compounded_highlight_positions(compounded, { index: activeSyllable.index, indexType: 'syllable' });
          setCompoundedHighlight([[result.highlight.index, result.highlight.length]]);
        }
      }
    }
  }, [activeSyllable, compounded, context]);

  function saveSettings(settings) {
    setContext({
      ...context,
      ...settings,
    })
    setShowSettingsDialog(false);
  }

  function handleCompoundedChange(event) {
    setCompounded(event.target.value);
  }

  function handleSeparatedChange(event) {
    setSeparated(event);
    // eslint-disable-next-line no-unused-vars
    const [result, _] = from_separated_to_compounded_cross_check(event.lyrics, event.gabc, event.nabc, event.metadata);
    setCompounded(result);
  }

  function handleCompoundedSelectionChange(event) {
    const result = find_compounded_highlight_positions(compounded, { index: event.target.selectionStart, indexType: 'cursor' });
    setCompoundedHighlight([[result.highlight.index, result.highlight.length]]);
    setActiveSyllable({
      index: result.syllableIndex,
      eventSource: 'classic',
    });
  }

  function handleSyllableClicked(event) {
    const syllable = find_syllable_group(event.target);
    const index = +syllable.classList[1].replace('syllable-', '');
    setActiveSyllable({
      index: index,
      eventSource: 'chant',
    });
  }

  function copyUrl() {
    const encoded = encodeURIComponent(compounded);
    const url = window.location.origin + window.location.pathname + '#q=' + encoded;
    navigator.clipboard.writeText(url);
  }

  function handleErrorsChange(errors) {
    setErrors(errors);
  }

  function onResize(event, { node, size, handle }) {
    setLeftPanelWidth(size.width);
  };

  function uploadFile(file) {
    if (file.name.endsWith('.nabc') || file.name.endsWith('.gabc')) {
      file.text().then((text) => {
        setCompounded(text);
      });
    }
  }

  function handleFileUpload(file) {
    setShowFileDialog(false);
    uploadFile(file);
  }

  function handleGlobalDrop(event) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    uploadFile(file);
    setDragging(false);
  }

  const split = useRef(null);
  function toggleSplitBtn() {
    split.current.show();
  }

  const chantRef = useRef(null);

  const exportItems = [
    {
      label: 'Export as SVG',
      command: () => {
        chantRef.current.exportSvg('chant.svg');
      }
    },
    {
      label: 'Export as PNG',
      command: () => {
        chantRef.current.exportPng('chant.png');
      }
    },
    {
      label: 'Export as PDF',
      command: () => {
        chantRef.current.exportPdf('chant.pdf');
      }
    },
  ];

  const chant = useMemo(() => <Chant ref={chantRef} context={context} compounded={deferredCompounded}
    onErrors={handleErrorsChange} onSyllableClicked={handleSyllableClicked} activeSyllable={activeSyllable}></Chant>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [context, deferredCompounded]);

  function handleGlobalDragOver(event) {
    event.preventDefault();
    setDragging(true);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setDragging(false);
    }, 1000);
  }

  return (
    <div onDrop={handleGlobalDrop} onDragOver={handleGlobalDragOver}
      className="h-screen flex flex-col relative">
      {dragging &&
        <div className="absolute top-0 left-0 w-screen h-screen z-50 flex justify-center items-center">
          <div className="absolute top-0 left-0 bottom-0 right-0 m-auto h-28 w-72 z-50 text-center bg-white flex border-primary rounded-xl">
            <div className=" border border-dashed flex grow items-center justify-center rounded-xl">
              <div className="font-semibold text-lg">
                Drop to upload file
              </div>
            </div>
          </div>
          <div className="absolute w-full h-full bg-gray-300 opacity-70 z-40">
          </div>
        </div>}
      <div className="flex items-center gap-8 p-8 h-20 bg-primary">
        <img className="h-12" src="assets/neumz_logo_large_white.png" alt="" />
        <div id="header" className="text-2xl font-semibold text-white">NABC Renderer</div>
      </div>
      <div className="flex flex-1 h-0">
        <Resizable className="h-full overflow-y-auto" axis="x" width={leftPanelWidth} onResize={onResize} handleSize={[100, 100]}>
          <div className="p-6 shadow-lg"
            style={{ width: leftPanelWidth + 'px' }}>
            <div className='flex flex-col gap-4 h-full'>
              <div className="flex justify-between gap-4">
                <SelectButton value={mode} onChange={(e) => setMode(e.value)} options={modeOptions} />
                <div className="flex gap-4">
                  <div className="flex gap-2">
                    <SplitButton ref={split} label="Export" onClick={toggleSplitBtn} model={exportItems} />
                  </div>
                  <div className="flex gap-2">
                    <Button className="btn-squared text-xl" onClick={() => setShowInstructionDialog(true)}>
                      <FontAwesomeIcon icon="circle-info" />
                    </Button>
                    <Dialog header="Instruction" visible={showInstructionDialog} style={{ width: '30vw' }} onHide={() => setShowInstructionDialog(false)}>
                      <ol className="list-decimal pl-4">
                        <li>
                          Words are automatically separated by syllables.
                        </li>
                        <li>
                          to put multiple emements to one syllable use & (ampersand) as delimeter.<br />
                          ex. <em>(f|vi-|hv|vi-|hf|cl-) = gabc: f&hv&hf ; nabc: vi-&vi-&cl-</em>
                        </li>
                        <li>
                          to add space wrap entire segment with parentheses.
                        </li>
                        <li>
                          to add space wrap entire segment with parentheses.
                          ex. text(hf ;) = gabc: (hf ;)
                        </li>
                        <li>
                          every gabc should have corresponding nabc notation. If no nabc needed then it is required to put empty parentheses.<br />
                          ex. <em>Vir(c) e(e!f'g)rat...(f.) (::) = lyrics: Vir erat... ; gabc: c e!f'g f. ; nabc: () () ()</em>
                        </li>
                      </ol>
                    </Dialog>
                    <Button className="btn-squared text-xl" placeholder="Bottom" tooltip="Get Shareable Link"
                      tooltipOptions={{ position: 'bottom' }} onClick={() => copyUrl()}>
                      <FontAwesomeIcon icon="copy" />
                    </Button>
                    <Button className="btn-squared text-xl" onClick={() => setShowFileDialog(true)}>
                      <FontAwesomeIcon icon="upload" />
                    </Button>
                    <Dialog header="Upload File" visible={showFileDialog} style={{ width: '20vw' }} onHide={() => setShowFileDialog(false)}>
                      <FileUpload onFileUpload={handleFileUpload}></FileUpload>
                    </Dialog>
                    <Button className="btn-squared text-xl" onClick={() => setShowSettingsDialog(true)} >
                      <FontAwesomeIcon icon="gear" />
                    </Button>
                    <Dialog header="Settings" visible={showSettingsDialog} style={{ width: '40vw' }} onHide={() => setShowSettingsDialog(false)}>
                      <Settings context={context} onSave={(e) => saveSettings(e)} onCancel={(e) => setShowSettingsDialog(false)}></Settings>
                    </Dialog>
                  </div>
                </div>
              </div>
              {
                mode === 'Classic'
                  ?
                  <TextareaHighlighted inputId="classic" eventKey="classic" value={compounded} onChange={handleCompoundedChange} scrollEnabled={context.scrollEnabled}
                    onSelect={handleCompoundedSelectionChange} highlights={compoundedHighlight} activeSyllable={activeSyllable} rows={20}></TextareaHighlighted>
                  : <Separated lyrics={separated.lyrics} gabc={separated.gabc} nabc={separated.nabc} metadata={separated.metadata} onChange={handleSeparatedChange}
                    activeSyllable={activeSyllable} onActiveSyllableChange={setActiveSyllable} scrollEnabled={context.scrollEnabled}></Separated>
              }
              <div className="flex-1 flex flex-col">
                <label>Output</label>
                <div className="flex-1 flex flex-col overflow-y-auto textarea">
                  <div>
                    {
                      settings.debug && errors.lexResult && errors.lexResult.map((r, index) => <div key={r.startOffset}>{r.startOffset} {r.tokenType.name}</div>)
                    }
                  </div>
                  <div>
                    {errors.lexErrors && errors.lexErrors.map((error, index) => <div key={error.startOffset}>{error.image}</div>)}
                  </div>
                  <div>
                    {errors.parseErrors && errors.parseErrors.map((error, index) => <div key={error.message}>{error.message}</div>)}
                  </div>
                  <div>
                    {errors.message && <div>{errors.message.toString()}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Resizable>
        <div id='scroll-parent' className="p-[30px] flex-1 h-full overflow-y-auto">
          {chant}
        </div>
      </div>
    </div>
  );
}
