import { GregorianChantSVGRenderer, GregorioScore, set_syllable_event, highlight_syllable } from '@testneumz/nabc-lib';
import React, { forwardRef, useEffect, useRef, useImperativeHandle, useMemo } from 'react';

export const Chant = forwardRef(function Chant({ context, compounded, onErrors, onSyllableClicked, activeSyllable }, ref) {
  const containerRef = useRef(null);
  const rendererRef = useRef(null);
  const cachedActiveSyllable = useMemo(() => {
    return activeSyllable;
  }, [activeSyllable])

  useImperativeHandle(ref, () => ({
    exportSvg(filename) {
      return rendererRef.current.exportSvg(filename);
    },
    exportPng(filename) {
      return rendererRef.current.exportPng(filename);
    },
    exportPdf(filename) {
      return rendererRef.current.exportPdf(filename);
    },
  }), []);

  useEffect(() => {
    if (rendererRef.current === null) {
      rendererRef.current = new GregorianChantSVGRenderer(containerRef.current);
    }

    if (!compounded) {
      return;
    }

    let result = null;
    try {
      const score = new GregorioScore(context);
      result = score.interprete(compounded);
      rendererRef.current.renderSvg(score);
      set_syllable_event(containerRef.current, { 'click': onSyllableClicked });

      if (cachedActiveSyllable) {
        const query = document.getElementsByClassName('gregorio-chant');
        highlight_syllable(query[0], cachedActiveSyllable.index, { fill: 'red' }, {});
      }

      onErrors({});
    } catch (e) {
      console.error(e);
      onErrors({
        lexResult: result?.lexResult,
        lexErrors: result?.lexErrors,
        parseErrors: result?.parseErrors,
        message: e,
      });
    }

    return () => {
      rendererRef.current.destroy();
    };
  }, [onErrors, onSyllableClicked, context, compounded, cachedActiveSyllable]);

  return <div className='h-full chant-parent' ref={containerRef}></div>
});
