import { useEffect, useMemo, useRef } from 'react';
import './TextareaHighlighted.css';

export function TextareaHighlighted({ inputId, value, onChange, onSelect, highlights, eventKey, activeSyllable, rows, scrollEnabled }) {
  const highlightsContent = useMemo(() => {
    let result = '';
    let counter = 0;
    for (let i = 0; i < highlights.length; i++) {
      const highlight = highlights[i];
      const index = highlight[0];
      const length = highlight[1];

      result += value.substring(0, index).replace(/</g, '&lt;').replace(/>/g, '&gt;');
      result += '<mark class="highlighted">';
      result += value.substring(index, index + length).replace(/</g, '&lt;').replace(/>/g, '&gt;');
      result += '</mark>';
      counter += index + length;
    }
    result += value.substring(counter).replace(/</g, '&lt;').replace(/>/g, '&gt;');
    result = result.replace(/\n/g, '<br>');
    if (result.endsWith('<br>')) {
      result += '&nbsp;';
    }
    return result;
  }, [value, highlights]);


  const backdropRef = useRef(null);
  const textareaRef = useRef(null);

  function syncBackdrop(event) {
    const target = event.target;
    backdropRef.current.scrollTop = target.scrollTop;
    backdropRef.current.scrollLeft = target.scrollLeft;
  }

  useEffect(() => {
    if (!scrollEnabled || eventKey === activeSyllable.eventSource) {
      return;
    }
    scrollToCharacter(highlights[0][0]);
  }, [eventKey, highlights, activeSyllable, scrollEnabled]);


  function scrollToCharacter(charIndex) {
    const textarea = textareaRef.current;
    if (charIndex >= 0 && charIndex <= textarea.value.length) {
      const fullText = textarea.value;
      textarea.value = fullText.substring(0, charIndex);
      const height = textarea.scrollHeight;
      textarea.value = fullText;
      if (height <= textarea.clientHeight) {
        textarea.scrollTop = height - textarea.clientHeight;
      } else {
        textarea.scrollTop = height - textarea.clientHeight / 2;
      }
    }
  }

  return useMemo(() =>
    <div className="relative">
      <div ref={backdropRef} className="textarea-backdrop" dangerouslySetInnerHTML={{ __html: highlightsContent }}>
      </div>
      <textarea id={inputId}
        rows={rows}
        style={{ 'resize': 'none' }}
        spellCheck={false}
        ref={textareaRef}
        className='h-full textarea-highlited'
        value={value}
        onScroll={syncBackdrop}
        onFocus={syncBackdrop}
        onChange={onChange}
        onSelect={onSelect}></textarea>
    </div>,
    [inputId, value, highlightsContent, onChange, onSelect, rows],
  );
}
