import { Button } from 'primereact/button';
import { useState } from 'react';

export function FileUpload({ onFileUpload }) {
  const [file, setFile] = useState(null);
  function handleChange(e) {
    setFile(e.target.files[0]);
  }

  function handleClick() {
    onFileUpload(file);
  }

  return (
    <form className="flex flex-col gap-4">
      <input type="file" onChange={handleChange} />
      <div className="flex justify-end">
        <Button className="btn" type="button" label="Upload" onClick={handleClick}></Button>
      </div>
    </form>
  );
}
